<template>
  <div id="header">
    <div id="header_line">
      <img
        v-if="appStyle == 'stats'"
        src="~@/assets/images/general/header_stats.svg"
        alt
      />
      <img v-else src="~@/assets/images/general/header.svg" alt />
    </div>
    <div id="header_display">
      <!-- Disabilito Zoom          
          <div id="header_size" v-if="this.$route.name == 'field'">
          <Zoom />
        </div>
        -->
      <Set
        v-if="this.$route.name == 'field' && hasTactic"
        @click.native="openPoly"
        :text="'POLY'"
        :x="'15.8325195'"
      />
      <Set
        v-if="this.$route.name == 'table' && !isHistorical"
        @click.native="openTableMode"
        :text="'MODE'"
        :x="'15.8325195'"
      />
      <div v-if="this.$route.name == 'field' && hasVideo" @click="videoActive">
        <playvideo v-if="!videoButton" />
        <playvideoactive v-if="videoButton" />
      </div>
      <Set
        v-if="this.$route.name == 'table'"
        @click.native="routeToField"
        :text="'FIELD'"
        :x="'21.5'"
      />
      <div v-if="this.$route.name == 'table'" @click="homeActive">
        <home v-if="!homeButton" />
        <homeactive v-if="homeButton" />
      </div>
      <Set
        v-if="this.$route.name == 'field'"
        @click.native="openView"
        :text="'VIEW'"
        :x="'25.8325195'"
      />
      <Timer />
      <div v-if="this.$route.name == 'facetoface'" class="graphToggle">
        <div class="temporal" @click="radarActive">
          <temporal v-if="radarButton" />
          <temporalfill v-if="!radarButton" />
          <!--
               <img
              v-if="radarButton"
              src="~@/assets/images/faceto/temporal.svg"
              alt
            />
            <img
              v-else
              class="temporal_fill"
              src="~@/assets/images/faceto/temporal-fill.svg"
              alt
            /> 
            -->
        </div>
        <div class="radar" @click="radarActive">
          <radar v-if="!radarButton" />
          <radarfill v-if="radarButton" />
          <!--            <img
              v-if="!radarButton"
              src="~@/assets/images/faceto/radar.svg"
              alt
            />
            <img
              v-else
              class="radar_fill"
              src="~@/assets/images/faceto/radar-fill.svg"
              alt
            />
        --></div>
      </div>
      <div v-if="this.$route.name == 'table'" @click="awayActive">
        <away v-if="!awayButton" />
        <awayactive v-if="awayButton" />
      </div>
      <div v-if="this.$route.name == 'table'" @click="exportcsv">
        <exportcsv />
      </div>
      <div id="header_view"></div>
      <Set
        v-if="
          this.$route.name == 'field' ||
            (this.$route.name == 'facetoface' && !radarButton)
        "
        @click.native="openSet"
        :text="'SET'"
        :x="'30.5'"
      />
      <Notifications />
    </div>
  </div>
</template>

<script>
import Timer from "@/components/general/header/elements/Timer";
import Zoom from "@/components/general/header/elements/Zoom";
import Set from "@/components/general/header/elements/Set";
import away from "@/components/general/header/table/away";
import awayactive from "@/components/general/header/table/awayactive";
import home from "@/components/general/header/table/home";
import homeactive from "@/components/general/header/table/homeactive";
import playvideo from "@/components/general/header/table/playvideo";
import playvideoactive from "@/components/general/header/table/playvideoactive";
import exportcsv from "@/components/general/header/table/exportcsv";
import Notifications from "@/components/general/header/elements/Notifications";
import temporal from "@/components/general/header/facetoface/temporal";
import temporalfill from "@/components/general/header/facetoface/temporal-fill";
import radar from "@/components/general/header/facetoface/radar";
import radarfill from "@/components/general/header/facetoface/radar-fill";
export default {
  data() {
    return {
      home: true,
      away: false,
      playvideo: false,
      radar: this.radarButton,
    };
  },
  components: {
    Timer,
    Set,
    away,
    awayactive,
    home,
    homeactive,
    //Zoom,
    exportcsv,
    Notifications,
    playvideo,
    playvideoactive,
    temporal,
    temporalfill,
    radar,
    radarfill,
  },
  computed: {
    awayButton() {
      return this.$store.state.table.away;
    },
    homeButton() {
      return this.$store.state.table.home;
    },
    radarButton() {
      return this.$store.state.faceto.radar;
    },
    hasVideo() {
      return this.$store.state.hasVideo;
    },
    videoButton() {
      return this.$store.state.playvideo;
    },
    appStyle() {
      return this.$store.state.appStyle;
    },
    hasTactic() {
      return this.$store.state.user.user.permissions.includes("TACTIC");
    },
    isHistorical() {
      return this.$store.state.table.tableMode == "Historical";
    },
  },
  methods: {
    openSet() {
      this.$store.commit("set", true);
    },
    openView() {
      this.$store.commit("view", true);
    },
    openPoly() {
      this.$store.commit("poly", true);
    },
    openSetGame() {
      this.$store.commit("setgame", true);
    },
    openSetPost() {
      this.$store.commit("setpost", true);
    },
    homeActive() {
      this.home = !this.home;
      this.$store.commit("home", this.home);
    },
    awayActive() {
      this.away = !this.away;
      this.$store.commit("away", this.away);
    },
    radarActive() {
      this.radar = !this.radar;
      // console.log(this.radar);
      this.$store.commit("radar", this.radar);
    },
    exportcsv() {
      this.$store.commit("exportcsv", true);
    },
    routeToField() {
      this.$router.push("/field");
    },
    videoActive() {
      this.playvideo = !this.playvideo;
      this.$store.commit("playvideo", this.playvideo);
    },
    openTableMode() {
      this.$store.commit("tableModeToggle", true);
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  max-width: 90%;
  margin: 20px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1000;
  &_line {
    width: 100%;
    img {
      width: 100%;
      margin-bottom: 5px;
    }
  }
  &_display {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &_view {
  }
  .graphToggle {
    display: flex;

    .temporal {
      position: relative;
      &_fill {
      }
    }
    .radar {
      position: relative;
      margin-left: -11px;
      &_fill {
      }
    }
  }
}
</style>
