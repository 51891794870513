var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    _vm._l(
      _vm.snackbars.filter(function(s) {
        return s.showing
      }),
      function(snackbar) {
        return _c(
          "v-snackbar",
          {
            key: snackbar.text + Math.random(),
            style: "bottom: 8px",
            attrs: {
              timeout: snackbar.timeout,
              color: snackbar.color,
              dark: snackbar.dark,
              height: snackbar.height,
              bottom: "",
              right: ""
            },
            model: {
              value: snackbar.showing,
              callback: function($$v) {
                _vm.$set(snackbar, "showing", $$v)
              },
              expression: "snackbar.showing"
            }
          },
          [
            _vm._v(" " + _vm._s(snackbar.text) + " "),
            _c(
              "v-btn",
              {
                attrs: { text: "" },
                on: {
                  click: function($event) {
                    snackbar.showing = false
                  }
                }
              },
              [_vm._v(" X ")]
            )
          ],
          1
        )
      }
    ),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }