<template>
  <div :class="'header_set ' + classes">
    <svg
      width="88px"
      height="32px"
      viewBox="0 0 88 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Top bar/option/set</title>
      <g
        id="Top-bar/option/set"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <path
          d="M12.059273,1 L88,1 L88,1 L78.6771388,31 L2.67896794,31 C2.40282556,31 2.17896794,30.7761424 2.17896794,30.5 C2.17896794,30.449688 2.18656158,30.3996642 2.2014923,30.3516187 L11.1043217,1.70323739 C11.2342948,1.28499747 11.6213031,1 12.059273,1 Z"
          id="Rectangle-Copy-14"
        />
        <text
          id="SET"
          fill="#FFFFFF"
          fill-rule="nonzero"
          font-family="Helvetica"
          font-size="15"
          font-weight="normal"
          style="text-align: center"
        >
          <tspan :x="x" y="22">{{ text }}</tspan>
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  methods: {},
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "#FDF800",
    },
    x: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  path {
    stroke: var(--hb-stroke);
  }

  text {
    fill: var(--hb-fill);
  }

</style>