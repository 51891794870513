<template>
  <svg
    width="88px"
    height="32px"
    viewBox="0 0 88 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Group</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Top-bar/option/away" transform="translate(-1.000000, 0.000000)">
        <g id="Group" transform="translate(2.000000, 1.000000)">
          <path
            d="M10.059273,0 L86,0 L86,0 L76.6771388,30 L0.678967938,30 C0.402825563,30 0.178967938,29.7761424 0.178967938,29.5 C0.178967938,29.449688 0.186561581,29.3996642 0.201492296,29.3516187 L9.10432169,0.703237392 C9.23429478,0.284997465 9.62130307,1.52457842e-15 10.059273,0 Z"
            id="Rectangle-Copy-14"
            stroke="#FDF800"
          />
          <text
            id="AWAY"
            fill="#FFFFFF"
            fill-rule="nonzero"
            font-family="Helvetica"
            font-size="15"
            font-weight="normal"
          >
            <tspan x="21.4138184" y="21">AWAY</tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
  path {
    stroke: var(--hb-stroke);
  }

  text {
    fill: var(--hb-fill);
  }

</style>