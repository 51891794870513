<template>
  <svg
    width="127px"
    height="32px"
    viewBox="0 0 127 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Group</title>
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Group" transform="translate(1.000000, 1.000000)">
        <path
          d="M10.059273,0 L125,0 L125,0 L115.677139,30 L0.678967938,30 C0.402825563,30 0.178967938,29.7761424 0.178967938,29.5 C0.178967938,29.449688 0.186561581,29.3996642 0.201492296,29.3516187 L9.10432169,0.703237392 C9.23429478,0.284997465 9.62130307,-8.08559378e-16 10.059273,0 Z"
          id="Rectangle-Copy-14"
        ></path>
        <text
          id="TEMPORAL"
          fill-rule="nonzero"
          font-family="Helvetica"
          font-size="15"
          font-weight="normal"
        >
          <tspan x="21.2426758" y="21">TEMPORAL</tspan>
        </text>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
path {
  stroke: var(--hb-stroke);
  fill: var(--hb-stroke);
}

text {
  fill: var(--hb-fill-reverse);
}
</style>
