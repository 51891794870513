var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "header_timer" } }, [
    _c("div", { attrs: { id: "header_timer:time" } }, [
      _vm._v("TIME " + _vm._s(_vm.time))
    ]),
    _c("div", { attrs: { id: "header_timer:process" } }, [
      _c("div", { class: "process one " + _vm.firsthalf }),
      _c("div", { class: "process two " + _vm.secondhalf }),
      _vm.extraPeriod > 2
        ? _c("div", { class: "process three " + _vm.thirdhalf })
        : _vm._e(),
      _vm.extraPeriod > 2
        ? _c("div", { class: "process four " + _vm.fourthhalf })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }