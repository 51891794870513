<template>
  <div class="">
    <v-snackbar
      v-for="(snackbar) in snackbars.filter((s) => s.showing)"
      :key="snackbar.text + Math.random()"
      v-model="snackbar.showing"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      :dark="snackbar.dark"
      :height="snackbar.height"
      bottom
      right
      :style="`bottom: 8px`"
    >
      {{ snackbar.text }}

      <v-btn text @click="snackbar.showing = false">
          X
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  watch: {},
  computed: {
    snackbars() {
      return this.$store.state.snackbar.snackbars;
    },
  },
  methods: {},
  mounted() {},
};
</script>
