var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "header" } }, [
    _c("div", { attrs: { id: "header_line" } }, [
      _vm.appStyle == "stats"
        ? _c("img", {
            attrs: {
              src: require("@/assets/images/general/header_stats.svg"),
              alt: ""
            }
          })
        : _c("img", {
            attrs: {
              src: require("@/assets/images/general/header.svg"),
              alt: ""
            }
          })
    ]),
    _c(
      "div",
      { attrs: { id: "header_display" } },
      [
        this.$route.name == "field" && _vm.hasTactic
          ? _c("Set", {
              attrs: { text: "POLY", x: "15.8325195" },
              nativeOn: {
                click: function($event) {
                  return _vm.openPoly($event)
                }
              }
            })
          : _vm._e(),
        this.$route.name == "table" && !_vm.isHistorical
          ? _c("Set", {
              attrs: { text: "MODE", x: "15.8325195" },
              nativeOn: {
                click: function($event) {
                  return _vm.openTableMode($event)
                }
              }
            })
          : _vm._e(),
        this.$route.name == "field" && _vm.hasVideo
          ? _c(
              "div",
              { on: { click: _vm.videoActive } },
              [
                !_vm.videoButton ? _c("playvideo") : _vm._e(),
                _vm.videoButton ? _c("playvideoactive") : _vm._e()
              ],
              1
            )
          : _vm._e(),
        this.$route.name == "table"
          ? _c("Set", {
              attrs: { text: "FIELD", x: "21.5" },
              nativeOn: {
                click: function($event) {
                  return _vm.routeToField($event)
                }
              }
            })
          : _vm._e(),
        this.$route.name == "table"
          ? _c(
              "div",
              { on: { click: _vm.homeActive } },
              [
                !_vm.homeButton ? _c("home") : _vm._e(),
                _vm.homeButton ? _c("homeactive") : _vm._e()
              ],
              1
            )
          : _vm._e(),
        this.$route.name == "field"
          ? _c("Set", {
              attrs: { text: "VIEW", x: "25.8325195" },
              nativeOn: {
                click: function($event) {
                  return _vm.openView($event)
                }
              }
            })
          : _vm._e(),
        _c("Timer"),
        this.$route.name == "facetoface"
          ? _c("div", { staticClass: "graphToggle" }, [
              _c(
                "div",
                { staticClass: "temporal", on: { click: _vm.radarActive } },
                [
                  _vm.radarButton ? _c("temporal") : _vm._e(),
                  !_vm.radarButton ? _c("temporalfill") : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "radar", on: { click: _vm.radarActive } },
                [
                  !_vm.radarButton ? _c("radar") : _vm._e(),
                  _vm.radarButton ? _c("radarfill") : _vm._e()
                ],
                1
              )
            ])
          : _vm._e(),
        this.$route.name == "table"
          ? _c(
              "div",
              { on: { click: _vm.awayActive } },
              [
                !_vm.awayButton ? _c("away") : _vm._e(),
                _vm.awayButton ? _c("awayactive") : _vm._e()
              ],
              1
            )
          : _vm._e(),
        this.$route.name == "table"
          ? _c("div", { on: { click: _vm.exportcsv } }, [_c("exportcsv")], 1)
          : _vm._e(),
        _c("div", { attrs: { id: "header_view" } }),
        this.$route.name == "field" ||
        (this.$route.name == "facetoface" && !_vm.radarButton)
          ? _c("Set", {
              attrs: { text: "SET", x: "30.5" },
              nativeOn: {
                click: function($event) {
                  return _vm.openSet($event)
                }
              }
            })
          : _vm._e(),
        _c("Notifications")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }