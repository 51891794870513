var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "127px",
        height: "32px",
        viewBox: "0 0 127 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("title", [_vm._v("Group")]),
      _c(
        "g",
        {
          attrs: {
            id: "Symbols",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              attrs: { id: "Group", transform: "translate(1.000000, 1.000000)" }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M10.059273,0 L125,0 L125,0 L115.677139,30 L0.678967938,30 C0.402825563,30 0.178967938,29.7761424 0.178967938,29.5 C0.178967938,29.449688 0.186561581,29.3996642 0.201492296,29.3516187 L9.10432169,0.703237392 C9.23429478,0.284997465 9.62130307,-8.08559378e-16 10.059273,0 Z",
                  id: "Rectangle-Copy-14"
                }
              }),
              _c(
                "text",
                {
                  attrs: {
                    id: "TEMPORAL",
                    "fill-rule": "nonzero",
                    "font-family": "Helvetica",
                    "font-size": "15",
                    "font-weight": "normal"
                  }
                },
                [
                  _c("tspan", { attrs: { x: "21.2426758", y: "21" } }, [
                    _vm._v("TEMPORAL")
                  ])
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }