<template>
  <div id="header_timer">
    <div id="header_timer:time">TIME {{ time }}</div>
    <div id="header_timer:process">
      <div :class="'process one ' + firsthalf"></div>
      <div :class="'process two ' + secondhalf"></div>
      <div :class="'process three ' + thirdhalf" v-if="extraPeriod > 2"></div>
      <div :class="'process four ' + fourthhalf" v-if="extraPeriod > 2"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: null,
      firsthalf: null,
      secondhalf: null,
      thirdhalf: null,
      fourthhalf: null,
      extraPeriod: 0,
    };
  },
  watch: {
    timer(val) {
      this.time = val;
    },
    period(val) {
      this.periodActive(val);
    },
  },
  computed: {
    timer() {
      return this.$store.state.time.timer;
    },
    period() {
      return this.$store.state.time.period;
    },
  },
  methods: {
    periodActive(val) {
      //console.log(parseInt(val));
      if (val == "1") {
        this.firsthalf = "active";
        this.secondhalf = null;
        this.thirdhalf = null;
        this.fourthhalf = null;
      } else if (val == "2") {
        this.firsthalf = null;
        this.secondhalf = "active";
        this.thirdhalf = null;
        this.fourthhalf = null;
      } else if (val == "3") {
        this.firsthalf = null;
        this.secondhalf = null;
        this.thirdhalf = "active";
        this.fourthhalf = null;
      } else if (val == "4") {
        this.firsthalf = null;
        this.secondhalf = null;
        this.thirdhalf = null;
        this.fourthhalf = "active";
      } else {
        this.firsthalf = null;
        this.secondhalf = null;
        this.thirdhalf = null;
        this.fourthhalf = null;
      }

      this.extraPeriod = parseInt(val);

    },
  },
  mounted() {
    this.periodActive(this.period);
  },
};
</script>

<style lang="scss" scoped>
#header {
  &_timer {
    color: var(--color);
    width: 100px;
    text-align: center;
    margin: 0 30px;
    &\:process {
      display: flex;
      flex-direction: row;
      .process {
        margin-top: 5px;
        height: 4px;
        width: 50%;
        background-color: rgb(175, 175, 175);
      }
      .process.one,
      .process.two,
      .process.three,
      .process.four {
        margin: 2px;
      }
      .active {
        background-color: var(--timer-bg-color);
      }
    }
  }
}
</style>