var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "svg",
      {
        attrs: {
          width: "152px",
          height: "32px",
          viewBox: "0 0 152 32",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink"
        }
      },
      [
        _c("title", [_vm._v("Top bar/size field/complete")]),
        _c(
          "g",
          {
            attrs: {
              id: "Top-bar/size-field/complete",
              stroke: "none",
              "stroke-width": "1",
              fill: "none",
              "fill-rule": "evenodd"
            }
          },
          [
            _c(
              "g",
              {
                attrs: {
                  id: "Top-bar/size-field/S-off",
                  transform: "translate(0.000000, 12.000000)"
                },
                on: {
                  click: function($event) {
                    return _vm.small()
                  }
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M6.20213914,1 L40.7614902,1 L40.7614902,1 L36.4579733,19 L1.63755923,19 C1.36141685,19 1.13755923,18.7761424 1.13755923,18.5 C1.13755923,18.459838 1.14239812,18.4198223 1.15197131,18.3808179 L5.2309633,1.76163578 C5.34072297,1.31443806 5.74166874,1 6.20213914,1 Z",
                    id: "Rectangle-Copy-4",
                    stroke: "#FDF800",
                    "stroke-width": "1"
                  }
                }),
                _c(
                  "text",
                  {
                    attrs: {
                      id: "S",
                      fill: "#FFFFFF",
                      "fill-rule": "nonzero",
                      "font-family": "Helvetica",
                      "font-size": "12",
                      "font-weight": "normal"
                    }
                  },
                  [
                    _c("tspan", { attrs: { x: "16.4980469", y: "14" } }, [
                      _vm._v("S")
                    ])
                  ]
                )
              ]
            ),
            _c(
              "g",
              {
                attrs: {
                  id: "Top-bar/size-field/M-off",
                  transform: "translate(35.600000, 6.000000)"
                },
                on: {
                  click: function($event) {
                    return _vm.medium()
                  }
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M7.6391255,1 L54,1 L54,1 L48.238656,25 L1,25 L6.66760569,1.76304165 C6.77684596,1.31516087 7.17811504,1 7.6391255,1 Z",
                    id: "Rectangle-Copy-3",
                    stroke: "#FDF800",
                    "stroke-width": "1"
                  }
                }),
                _c(
                  "text",
                  {
                    attrs: {
                      id: "M",
                      fill: "#FFFFFF",
                      "fill-rule": "nonzero",
                      "font-family": "Helvetica",
                      "font-size": "15",
                      "font-weight": "normal"
                    }
                  },
                  [_c("tspan", { attrs: { x: "22", y: "19" } }, [_vm._v("M")])]
                )
              ]
            ),
            _c(
              "g",
              {
                attrs: {
                  id: "Top-bar/size-field/L-off",
                  transform: "translate(83.000000, 0.000000)"
                },
                on: {
                  click: function($event) {
                    return _vm.large()
                  }
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M9.04994415,1 L66.7290045,1 C67.2812893,1 67.7290045,1.44771525 67.7290045,2 C67.7290045,2.07927143 67.7195787,2.15826167 67.7009256,2.23530724 L60.9219769,30.2353072 C60.8133486,30.6839894 60.4117005,31 59.9500559,31 L1,31 L1,31 L8.07802311,1.76469276 C8.18665144,1.31601062 8.58829951,1 9.04994415,1 Z",
                    id: "Rectangle-Copy",
                    stroke: "#FDF800",
                    "stroke-width": "1"
                  }
                }),
                _c(
                  "text",
                  {
                    attrs: {
                      id: "L",
                      fill: "#FFFFFF",
                      "fill-rule": "nonzero",
                      "font-family": "Helvetica",
                      "font-size": "19",
                      "font-weight": "normal"
                    }
                  },
                  [
                    _c("tspan", { attrs: { x: "29.2165527", y: "24" } }, [
                      _vm._v("L")
                    ])
                  ]
                )
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }